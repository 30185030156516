var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"kt-menu__link",class:{ 'kt-menu__toggle': _vm.menu.submenu },attrs:{"to":_vm.link()}},[(_vm.menu.icon)?_c('i',{staticClass:"kt-menu__link-icon",class:_vm.menu.icon}):_vm._e(),(!_vm.menu.icon && !_vm.menu.root)?_c('i',{staticClass:"kt-menu__link-bullet",class:_vm.bullet()},[_c('span')]):_vm._e(),_c('span',{class:{
      'kt-menu__link-text': 1,
      'text-danger':
        (!_vm.isTfa && _vm.menu.page == 'twofa') ||
        (_vm.isSecurityWarning && _vm.menu.page == 'securityincidents') ||
        (_vm.menu.title == 'SECURITY' && (!_vm.isTfa || _vm.isSecurityWarning)),
    }},[_vm._v(_vm._s(_vm.menu.translate ? _vm.$t(_vm.menu.translate) : _vm.menu.title))]),(_vm.menu.submenu)?_c('i',{staticClass:"kt-menu__ver-arrow la la-angle-right"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }