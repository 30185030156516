<template>
  <!-- <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-bind:menu="menu" :key="i"></KTMenuItem>
    </template>
  </ul> -->
  <div class="headerStatistics" style="font-size: 11px;">
    <div class="mt-2 mr-5">
      <p class="my-2">
        <b class="fontWeight-600">{{ $t("HOME.BASIC_ACCOUNTS") }} : </b>
        {{ basicAccount }}
      </p>
      <p class="my-2">
        <b class="fontWeight-600">{{ $t("HOME.ENHANCED_ACCOUNTS") }} : </b>
        {{ enhancedAccount }}
      </p>
    </div>
    <div class="mt-2 mr-5">
      <p class="my-2" v-if="hasExchange">
        <b class="fontWeight-600">{{ $t("USERS.EXCHANGE_ACCOUNTS") }} : </b>
        {{ exchange }}
      </p>
      <p class="my-2" v-if="hasOffice">
        <b class="fontWeight-600">{{ $t("USERS.OFFICE365_ACCOUNTS") }} : </b>
        {{ office365 }}
      </p>
    </div>
    <div class="mt-2 mr-5">
      <p class="my-2">
        <b class="fontWeight-600">{{ $t("MENU.DOMAINS") }} : </b> {{ domains }}
      </p>
      <p class="my-2">
        <b class="fontWeight-600">{{ $t("COMMON.QUOTA") }} : </b> {{ quota }}
      </p>
    </div>
    <div class="mt-2 mr-5">
      <p class="my-2" v-if="hasZulip">
        <b class="fontWeight-600">{{ $t("ZULIP.ZULIP_ACCOUNTS") }} : </b>
        {{ zulipAccount }}
      </p>
    </div>
    <div class="mt-2 topBar-alert" v-if="!isTfa" @click="goTFA">
      <b-alert show variant="warning">
        {{ $t("HOME.DESC3") }}
      </b-alert>
    </div>
  </div>
</template>

<script>
// import KTMenuItem from "@/views/theme/header/MenuItem.vue";
import menuConfig from "@/common/config/menu.config.json";

export default {
  name: "KTHeaderMenu",
  // components: {
  //   KTMenuItem
  // },
  computed: {
    menuItems: function () {
      return menuConfig.header.items;
    },
    basicAccount: function () {
      return this.$store.state.status.basicAccount;
    },
    enhancedAccount: function () {
      return this.$store.state.status.enhancedAccount;
    },
    zulipAccount: function () {
      return this.$store.state.status.zulipAccount;
    },
    domains: function () {
      return this.$store.state.status.domains;
    },
    exchange: function () {
      return this.$store.state.status.exchange;
    },
    hasExchange: function () {
      return this.$store.state.status.hasExchange;
    },
    office365: function () {
      return this.$store.state.status.office365;
    },
    hasOffice: function () {
      return this.$store.state.status.hasOffice;
    },
    hasZulip: function () {
      return this.$store.state.status.hasZulip;
    },
    quota: function () {
      return this.$store.state.status.quota;
    },
    isTfa: function () {
      return localStorage.isTfa == 1;
    },
  },
  methods: {
    goTFA() {
      this.$router.push("/twofa");
    },
  },
};
</script>
