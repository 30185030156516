<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <template v-if="role == 1">
        <template v-if="menu.meta.subAdmins">
          <KTMenuItem
            v-if="!menu.section && subadmins == 9"
            :menu="menu"
            :key="i"
          ></KTMenuItem>
          <KTMenuSection
            v-if="menu.section && subadmins == 9"
            :menu="menu"
            :key="i"
          ></KTMenuSection>
        </template>
        <template v-else>
          <KTMenuItem
            v-if="!menu.section && hasAccess(menu.meta.permission)"
            :menu="menu"
            :key="i"
          ></KTMenuItem>
          <KTMenuSection
            v-if="menu.section"
            :menu="menu"
            :key="i"
          ></KTMenuSection>
        </template>
      </template>
      <template v-else>
        <template v-if="menu.meta.subAdmins">
          <KTMenuItem
            v-if="!menu.section && menu.meta.adminTwo && subadmins == 9"
            :menu="menu"
            :key="i"
          ></KTMenuItem>
          <KTMenuSection
            v-if="menu.section && subadmins == 9"
            :menu="menu"
            :key="i"
          ></KTMenuSection>
        </template>
        <template v-else>
          <KTMenuItem
            v-if="!menu.section && menu.meta.adminTwo"
            :menu="menu"
            :key="i"
          ></KTMenuItem>
          <KTMenuSection
            v-if="menu.section"
            :menu="menu"
            :key="i"
          ></KTMenuSection>
        </template>
      </template>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/aside/MenuItem.vue";
import KTMenuSection from "@/views/theme/aside/MenuSection.vue";
import menuConfig from "@/common/config/menu.config.json";

export default {
  name: "KTAsideMenu",
  components: {
    KTMenuItem,
    KTMenuSection,
  },
  methods: {
    hasAccess: (permission) => {
      if (permission == "all") return true;
      //console.log(permission);
      return localStorage.getItem(permission) > 0;
    },
  },
  computed: {
    menuItems: () => {
      return menuConfig.aside.items;
    },
    role: () => {
      return localStorage.user_role;
    },
    subadmins: () => {
      return localStorage.perm_subadmins;
    },
  },
};
</script>
