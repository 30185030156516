<template>
  <div>
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="kt-user-card__avatar">
        <img
          class="kt-hidden"
          alt="Pic"
          src="@/assets/media/users/300_25.jpg"
        />
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
          >{{ firstLetter }}</span
        >
      </div>
      <div class="kt-user-card__name">{{ currentUser }}</div>
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <div class="kt-notification__item hover-pointer" v-b-modal.changePassword>
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ $t("SETTINGS.CHANGE_PASSWORD") }}
          </div>
          <div class="kt-notification__item-time">
            {{ $t("SETTINGS.CHANGE_PASSWORD_DESC") }}
          </div>
        </div>
      </div>
      <div class="kt-notification__custom kt-space-between">
        <a
          href="#"
          v-on:click="onLogout()"
          class="btn btn-label btn-label-brand btn-sm btn-bold"
          >{{ $t("AUTH.SIGN_OUT") }}</a
        >
      </div>
    </div>
    <b-modal
      id="changePassword"
      ref="changePassword"
      :title="$t('SETTINGS.CHANGE_PASSWORD')"
      class="chPass-modal"
    >
      <b-container fluid>
        <b-row class="my-1">
          <b-col sm="5">
            <label for="newPass">{{ $t("SETTINGS.NEW_PASSWORD") }}</label>
          </b-col>
          <b-col sm="7">
            <b-form-input
              id="newPass"
              v-model="$v.form.newPass.$model"
              :placeholder="$t('SETTINGS.NEW_PASSWORD')"
              @input="checkPassword"
              @focus="isShowPassDesc = true"
              :state="valid_password"
            ></b-form-input>
            <ul class="ml-5 my-2" v-show="isShowPassDesc">
              <li
                v-bind:class="{
                  is_validPass: contains_eight_characters,
                }"
              >
                {{ $t("VALIDATION.MIN_LETTERS", { num: 8 }) }}
              </li>
              <li v-bind:class="{ is_validPass: contains_lowercase }">
                {{ $t("VALIDATION.LOWERCASE_LETTER") }}
              </li>
              <li v-bind:class="{ is_validPass: contains_uppercase }">
                {{ $t("VALIDATION.UPPERCASE_LETTER") }}
              </li>
              <li v-bind:class="{ is_validPass: contains_number }">
                {{ $t("VALIDATION.NUMBER_LETTER") }}
              </li>
              <li
                v-bind:class="{
                  is_validPass: contains_special_character,
                }"
              >
                {{ $t("VALIDATION.SPECIAL_LETTER") }}
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col sm="5">
            <label for="confirmPass">{{
              $t("SETTINGS.CONFIRM_PASSWORD")
            }}</label>
          </b-col>
          <b-col sm="7">
            <b-form-input
              id="confirmPass"
              v-model="$v.form.confPass.$model"
              :state="validateState('confPass')"
              :placeholder="$t('SETTINGS.CONFIRM_PASSWORD')"
              aria-describedby="input-conf-pass-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-conf-pass-feedback">{{
              $t("VALIDATION.IDENTICAL_FIELD")
            }}</b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="outline-secondary" v-on:click="modalClose()">
          {{ $t("COMMON.CANCEL") }}
        </b-button>
        <b-button variant="primary" v-on:click="changePassword()">
          {{ $t("SETTINGS.CHANGE") }}
        </b-button>
      </template>
    </b-modal>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth.module";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { postRequest } from "@/api/request.js";
export default {
  mixins: [validationMixin],
  name: "KTDropdownUser",
  data: () => ({
    modalShow: false,
    form: {
      newPass: null,
      confPass: null,
    },
    
    isShowPassDesc: false,
    password_length: 0,
    contains_eight_characters: false,
    contains_uppercase: false,
    contains_lowercase: false,
    contains_number: false,
    contains_special_character: false,
    valid_password: null,
  }),
  validations: {
    form: {
      newPass: {
        required,
        minLength: minLength(3),
      },
      confPass: {
        required,
        sameAsPassword: sameAs("newPass"),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    checkPassword() {
      let password = this.form.newPass;
      this.password_length = password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }
      this.contains_number = /\d/.test(password);
      this.contains_uppercase = /[A-Z]/.test(password);
      this.contains_lowercase = /[a-z]/.test(password);
      this.contains_special_character = format.test(password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true &&
        this.contains_lowercase === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT, { token: localStorage.id_token, action: "logout" })
        .then(() => this.$router.push({ name: "login" }));
    },
    modalClose() {
      this.form.newPass = null;
      this.form.confPass = null;
      this.valid_password = false;
      this.password_length = 0;
      this.contains_eight_characters = false;
      this.contains_uppercase = false;
      this.contains_lowercase = false;
      this.contains_number = false;
      this.contains_special_character = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs["changePassword"].hide();
    },
    changePassword() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      postRequest({
        action: "changePassword",
        token: localStorage.id_token,
        password1: this.form.newPass,
        password2: this.form.confPass,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose();
      });
    },
    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    },
    currentUser() {
      return localStorage.current_user;
    },
    firstLetter() {
      return localStorage.current_user.charAt(0);
    },
  },
};
</script>
