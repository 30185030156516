<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <KTHeaderMobile></KTHeaderMobile>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <KTAside v-if="asideEnabled"></KTAside>
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <KTHeader></KTHeader>
        <div
          class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          id="kt_content"
        >
          <!-- <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          ></KTSubheader> -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:title="pageTitle"
          ></KTSubheader>
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid"
            v-bind:class="{ 'kt-container--fluid': contentFluid }"
          >
            <v-app>
              <transition name="fade-in-up">
                <router-view></router-view>
              </transition>
            </v-app>
          </div>
        </div>
        <KTFooter></KTFooter>
        <b-modal ref="inactivity-modal" :title="$t('AUTO_CLOSE.TITLE')">
          <p class="my-1">{{ $t("AUTO_CLOSE.DESC") }}</p>
          <P>{{ $t("AUTO_CLOSE.TIME_REMAIN", { sec: timeleft }) }}</P>
          <template v-slot:modal-footer>
            <b-button variant="outline-secondary" v-on:click="onLogout()">
              {{ $t("COMMON.CANCEL") }}
            </b-button>
            <b-button variant="primary" v-on:click="modalClose()">
              {{ $t("COMMON.OK") }}
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <!-- <KTQuickPanel></KTQuickPanel> -->
    <KTScrollTop></KTScrollTop>
  </div>
</template>
<style lang="scss" scoped>
.theme--light.v-application {
  background: inherit;
}
</style>
<script>
import { mapGetters } from "vuex";
import KTAside from "@/views/theme/aside/Aside.vue";
import KTHeader from "@/views/theme/header/Header.vue";
import KTHeaderMobile from "@/views/theme/header/HeaderMobile.vue";
import KTFooter from "@/views/theme/footer/Footer.vue";
import HtmlClass from "@/common/htmlclass.service";
import KTSubheader from "@/views/theme/subheader/Subheader.vue";
// import KTStickyToolbar from "@/views/partials/layout/StickyToolbar.vue";
// import KTQuickPanel from "@/views/partials/layout/QuickPanel.vue";
import KTScrollTop from "@/views/partials/layout/ScrollTop";
import Loader from "@/views/partials/content/Loader.vue";
import { LOGOUT } from "@/store/auth.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/store/htmlclass.module.js";

export default {
  name: "Base",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    // KTStickyToolbar,
    // KTQuickPanel,
    KTScrollTop,
    Loader,
  },
  data: () => ({
    timeleft: 60,
    isRunning: true,
  }),
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");

    // initialize html element classes
    HtmlClass.init();
  },
  mounted() {
    // check if current user is authenticated
    // if (!this.isAuthenticated) {
    //   this.$router.push({ name: "login" });
    // }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
    }, 2000);

    this.addRemoveListenerMulti(
      "add",
      window,
      "mousemove keypress",
      this.showAutoModal
    );
  },
  methods: {
    addRemoveListenerMulti(eventType, element, eventNames, listener) {
      var events = eventNames.split(" ");
      for (var i = 0, iLen = events.length; i < iLen; i++) {
        if (eventType == "add")
          element.addEventListener(events[i], listener, false);
        else {
          element.removeEventListener(events[i], listener, false);
        }
      }
    },
    showAutoModal() {
      var that = this;
      clearTimeout(this.handler);
      if (this.isRunning) {
        this.handler = setTimeout(function () {
          if (that.$refs["inactivity-modal"]) {
            that.$refs["inactivity-modal"].show();
            that.isRunning = false;
            that.toggleTimer();
          }
        }, localStorage.getItem('timeout') || 300000);
      }
    },
    onLogout() {
      this.isRunning = true;
      this.toggleTimer();
      this.addRemoveListenerMulti(
        "remove",
        window,
        "mousemove keypress",
        this.showAutoModal
      );
      this.$store
        .dispatch(LOGOUT, { token: localStorage.id_token, action: "logout" })
        .then(() => this.$router.push({ name: "login" }));
    },
    modalClose() {
      this.$refs["inactivity-modal"].hide();
      this.timeleft = 60;
      this.isRunning = true;
      this.toggleTimer();
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(this.countdownTime, 1000);
      }
    },
    countdownTime() {
      if (this.timeleft <= 0) {
        this.isRunning = true;
        this.toggleTimer();
        this.onLogout();
      } else {
        this.timeleft = parseInt(this.timeleft) - 1;
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      // "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page laoder is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !!this.layoutConfig("loader.enabled");
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
